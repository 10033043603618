
import { Instructors } from "@/api";
import { Gender, Instructor, Service } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";
import TitleButtons from "@/components/common/TitleButtons.vue";
import InstructorCoursesList from "@/components/courses/InstructorCoursesList.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import moment from "moment";
import { toSnake } from "snake-camel";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    InstructorCoursesList,
    PageTitle,
    TitleButtons,
    ValidationProvider,
    ValidationObserver,
    PhoneInput
  },
  mixins: [PhoneCodes]
})
export default class EditInstructorsPage extends Vue {
  menuBirthday = false;
  menuVacationStart = false;
  menuVacationEnd = false;
  signatureButtonsArray = [
    {
      text: "Retake",
      icon: "mdi-pencil-box-outline",
      color: "white",
      action: "clear",
      activeClass: "",
      disabled: false
    },
    {
      text: "Sign",
      icon: "mdi-checkbox-marked-circle-outline",
      color: "dark",
      action: "save",
      activeClass: "",
      disabled: false
    }
  ];
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Sign",
      icon: "mdi-checkbox-marked-circle-outline",
      color: "gray",
      action: "signature"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save"
    }
  ];
  headersTitle = [
    { icon: "mdi-account-box", header: "Instructor Profile Editor" }
  ];
  instructor: Instructor = {
    id: null,
    name: "",
    email: "",
    phone: "",
    genderId: null,
    workingHourFrom: "",
    workingHourTo: "",
    serviceId: null,
    outsourceCost: "",
    vacantionFrom: "",
    vacantionTo: "",
    signature: "",
    teta: ""
  };
  servicesAndGenders: { genders: Array<Gender>; services: Array<Service> } = {
    services: [],
    genders: []
  };

  vacationStart = moment().format("YYYY-MM-DD");
  vacationEnd = moment().format("YYYY-MM-DD");
  get instructorId() {
    return this.$route.params.id;
  }
  get outsourceRules() {
    return this.isPermanent ? "" : "required";
  }
  get vacationStartDateFormatted() {
    return moment(this.vacationStart).format("DD/MM/YYYY");
  }
  get vacationEndDateFormatted() {
    return moment(this.vacationEnd).format("DD/MM/YYYY");
  }
  get isPermanent() {
    return this.instructor.serviceId === 1 || !this.instructor.serviceId;
  }
  get gender() {
    return this.servicesAndGenders.genders.find(
      (item: Gender) => item.id === this.instructor.genderId
    )?.name;
  }
  get genders() {
    return this.servicesAndGenders.genders.map((item: Gender) => item.name);
  }
  async created() {
    try {
      [this.instructor, this.servicesAndGenders] = await Promise.all([
        Instructors.info(this.instructorId),
        Instructors.servicesAdnGenders()
      ]);
      this.vacationStart = moment(
        this.formatDate(this.instructor.vacantionFrom)
      ).format("YYYY-MM-DD");
      this.vacationEnd = moment(
        this.formatDate(this.instructor.vacantionTo)
      ).format("YYYY-MM-DD");
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  onSign() {
    this.$modal.show("attend-modal");
    Vue.nextTick().then(() => {
      if (this.instructor.signature) {
        (this.$refs.signaturePad as Vue & {
          fromDataURL: (data: string) => void;
        }).fromDataURL(this.instructor.signature);
      }
    });
  }
  onCloseModal() {
    this.$modal.hide("attend-modal");
  }
  onClearSignature() {
    (this.$refs.signaturePad as Vue & {
      clearSignature: () => void;
    }).clearSignature();
  }
  async onSaveSignature() {
    const { isEmpty, data } = (this.$refs.signaturePad as Vue & {
      saveSignature: () => any;
    }).saveSignature();
    if (!isEmpty) {
      this.instructor.signature = data;
      this.onCloseModal();
    } else {
      await this.$dialog.error({
        text: "Signature should not be empty!",
        title: "Error",
        persistent: false,
        actions: {
          Yes: {
            color: "red",
            text: "OK"
          }
        }
      });
    }
  }
  async onGoBack() {
    await this.$router.push({ name: "Instructors" });
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }

  formatDate(date: string | undefined): string {
    if (date === undefined) {
      return "";
    }
    const [day, month, year] = date.split("-");
    return `${month}/${day}/${year}`;
  }
  async save() {
    const data = JSON.parse(JSON.stringify(this.instructor));
    delete data.permissions;
    delete data.roles;
    delete data.id;
    if (!data.signature) {
      delete data.signature;
    }
    if (!data.address) {
      delete data.address;
    }
    delete data.outsourceCost;
    if (!this.isPermanent) {
      data.outsourceCost = data?.outsourceCost?.toString();
    }
    data.vacantionFrom = moment(this.vacationStart).format("DD-MM-YYYY");
    data.vacantionTo = moment(this.vacationEnd).format("DD-MM-YYYY");
    const reqData = toSnake(data);
    try {
      await Instructors.update(this.instructorId, reqData);
      await this.$success(
        "<strong>Success!</strong> Instructor has been updated!"
      );

      if (this.$route.name === "EditInstructor") {
        await this.$router.push({ name: "Instructors" });
      }
    } catch (e) {
      const err = e as any;
      (this.$refs.instructorForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }
}
