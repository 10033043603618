
import { Instructors } from "@/api";
import { Course, CoursesWithPagination } from "@/api/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageTitle from "../common/PageTitle.vue";

@Component({
  components: { PageTitle }
})
export default class InstructorCoursesList extends Vue {
  headersTitle = [{ icon: "mdi-calendar-month", header: "Course Assign" }];
  options = {};
  courses: Array<Course> = [];
  page = 1;
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Duration",
      value: "duration"
    },
    {
      text: "Type",
      value: "courseType"
    },
    {
      text: "Price",
      value: "price"
    }
  ];
  coursesWithPagination: CoursesWithPagination = {
    meta: {
      perPage: 12
    }
  };
  @Prop({ required: true })
  instructorId!: string;
  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    await this.loadCourses(val.page);
  }

  async loadCourses(page: number) {
    try {
      const coursesWithPagination = await Instructors.courses(
        this.instructorId,
        page
      );
      this.courses = coursesWithPagination.data
        ? coursesWithPagination.data
        : [];
      this.coursesWithPagination = coursesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  currencyFormat(price: number) {
    return new Intl.NumberFormat("en", {
      minimumFractionDigits: 2
    }).format(price);
  }
}
