import { PhoneCodesListItem } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";

const CountryCodes = namespace("countryCodes");

@Component
export class PhoneCodes extends Vue {
  @CountryCodes.Getter("countryCodesList")
  countryCodesList!: PhoneCodesListItem[];
  @CountryCodes.Action("getCountryCodesList")
  getCountryCodesList!: () => Promise<PhoneCodesListItem[]>;

  async created() {
    if (
      !this.countryCodesList ||
      (this.countryCodesList && this.countryCodesList.length === 0)
    ) {
      await this.getCountryCodesList().catch(() => {
        //
      });
    }
  }
}
