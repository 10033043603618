
import { PhoneCodesListItem } from "@/store/types";
import { ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const CountryCodes = namespace("countryCodes");

@Component({ components: { ValidationProvider } })
export default class PhoneInput extends Vue {
  @CountryCodes.Getter("countryCodesList")
  countryCodesList!: PhoneCodesListItem[];

  @Prop({ default: true })
  required!: boolean;
  @Prop()
  label!: string;
  @Prop()
  value!: string;

  code: PhoneCodesListItem | null = null;
  // codes = [
  // { id: 1, label: "South Africa", dialCode: "+27", iso2: "ZA" }
  // { id: 2, label: "USA", dialCode: "+11", iso2: "US" }
  // ];

  get phone() {
    // if (this.value && this.code) {
    //   const withoutCode = this.value
    //     .replace(/\s/g, "")
    //     .replace(/\(/g, "")
    //     .replace(/\)/g, "")
    //     .replace(this.code.dialCode, "");
    //   return `${this.code.dialCode} ${withoutCode}`;
    // }
    return this.value ? this.value.replace(/\s/g, "") : "";
    // .replace(/\(/g, "")
    // .replace(/\)/g, "");
  }

  @Watch("code")
  codeChange(code: PhoneCodesListItem, oldCode: PhoneCodesListItem) {
    if (code !== oldCode && this.value) {
      let phone;
      if (this.value.startsWith(`+(${oldCode?.dialCode})`)) {
        phone = this.value.replace(oldCode?.dialCode, code?.dialCode);
      } else if (this.value.startsWith(`+(${code?.dialCode})`)) {
        phone = this.value;
      } else {
        phone = `+(${code.dialCode})${this.value}`;
      }

      this.inputHandler(phone);
    } else {
      const phone = this.value
        ? `+(${code?.dialCode})${this.value.replace("+", "")}`
        : `+(${code?.dialCode})`;
      this.inputHandler(phone);
    }
  }

  @Watch("value")
  phoneChange(val: string, oldVal: string) {
    if (oldVal != val) {
      this.codeExistCheck(val);
    }
  }

  inputHandler(val: string) {
    if (val) {
      const phone = val.replace(/\s/g, "");
      // .replace(/\(/g, "")
      // .replace(/\)/g, "");
      // console.log("val: ", phone);
      // if (this.code) {
      // if (val.startsWith("+") && val.length > 2) {
      // this.codeExistCheck(phone);
      // }
      // if (this.code && val.startsWith(`+(${this.code.dialCode})`)) {
      //   console.log("pppp: ", phone);

      //   const withoutCode = phone.replace(`+${this.code.dialCode}`, "");
      //   console.log("withoutCode: ", withoutCode);

      //   phone = `+(${this.code.dialCode})${withoutCode}`;
      // }

      this.$emit("input", `${phone}`);
    }
  }

  codeExistCheck(phone: string) {
    if (!this.code) {
      // Using regular expression to extract the content between parentheses
      const regex = /\((.*?)\)/;
      const match = phone.match(regex);

      if (match) {
        const extractedCode = match[1];
        const code = this.countryCodesList.find(
          item => item.dialCode == extractedCode
        );
        if (code) {
          this.code = code;
        }
      }
    }
    //   if (phone.startsWith("+") && phone.length > 2) {
    //     const existingCode = this.codes.find(item =>
    //       phone.startsWith(item.dialCode)
    //     );
    //     console.log("existingCode: ", existingCode);
    //     if (existingCode) {
    //       // if (!this.code) {
    //       //   this.code = existingCode;
    //       // }
    //       this.code = existingCode;
    //       // }
    //     }
    //   }
  }
}
